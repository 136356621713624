/* Leads.css */
/* Fade-in and fade-out transitions for the cards */
.fade-enter {
    opacity: 0;
    transform: translateY(20px);
  }
  
  .fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 500ms, transform 500ms;
  }
  
  .fade-exit {
    opacity: 1;
  }
  
  .fade-exit-active {
    opacity: 0;
    transition: opacity 500ms;
  }
  
  /* Custom truncation with gradient fade-out */
  .text-fade {
    position: relative;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .text-fade::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(to right, transparent, white);
    pointer-events: none;
  }
  